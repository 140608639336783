import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentContract, isCurrentContractLoaded, isCurrentContractLoading } from './../store/contract/contract.selectors';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { loadCurrentContract } from '../store/contract/contract.actions';
import { FeatureToggleService as GsFeatureToggleService } from '@gridscale/gs-services';
import { Contract } from '../store/contract/contract.reducer';
@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  private readonly currentContract$ = this.store.select(getCurrentContract);
  private readonly featureFlags: BehaviorSubject<{
    [p: string]: boolean;
  }> = new BehaviorSubject<{ [p: string]: boolean }>({});

  public readonly ready$ = new BehaviorSubject(false);

  constructor(private readonly store: Store, private readonly gsFeatureToggleService: GsFeatureToggleService) {
    this.currentContract$.pipe(
      filter(contract => !!contract)
    ).subscribe(contract => {
      this.getFeatureToggles(contract!);
    });

    this.store.select(isCurrentContractLoaded).pipe(
      withLatestFrom(this.store.select(isCurrentContractLoading)),
      take(1)
    ).subscribe(([loaded, loading]) => {
      if (!loaded && !loading) {
        this.store.dispatch(loadCurrentContract());
      }
    });

  }

  /**
   * like `hasFeatureFlag$` but returns a boolean instead of observable.
   * Must not be used before `ready$` is not true!
   */
  hasFeatureFlagSync(name: string | string[], mode?: undefined | '' | 'OR' | 'AND'): boolean {
    return this.checkFeatureFlag(this.featureFlags.value, name, mode);
  }

  /**
   * returns if a feature or a set of features is present and true
   * `name` is the name of the feature flag or an array of feature flags
   * `mode` can be set to 'AND' or 'OR' if `name` is an array and defined if *ALL* feature flags must be true to return true (AND), or if it is enough that *one* FeatureFlag is true (OR)
   */
  hasFeatureFlag$(name: string | string[], mode?: undefined | '' | 'OR' | 'AND'): Observable<boolean> {
    if (!name || name === '' || name.length === 0) {
      return of(true);
    }


    return this.featureFlags.pipe(
      filter(ff => !_.isEmpty(ff)),
      map(ff => this.checkFeatureFlag(ff, name, mode))
    );
  }

  private checkFeatureFlagDependencyCallStackSize = 0;
  /**
   * check dependencies to other features
   */
  private checkFeatureFlagDependency(featureFlags: Record<string, boolean>, featureFlag: string) {
    // check if we find feature dependencies
    let allowed = true;

    // ensure we don't exceed call-stack-size
    ++this.checkFeatureFlagDependencyCallStackSize;


    if (this.checkFeatureFlagDependencyCallStackSize > 3) {
      return true;
    }

    _.forEach(this.gsFeatureToggleService.DEPENDENCYS, featureDependency => {
      if (featureDependency.feature_to_disable === featureFlag) {
        if (!this.checkFeatureFlag(
          featureFlags,
          _.filter(
            featureDependency.feature_to_listen_to,
            ff => ff.indexOf('_fake') === -1
          ),
          'AND'
        )) {
          allowed = false;
          return false;
        }
      }
    });

    --this.checkFeatureFlagDependencyCallStackSize;

    return allowed;
  }

  /**
   * returns true if a feature is disabled because of dependencies
   */
  isFeatureFlagDisabledThruDependency(featureFlag: string) {
    this.checkFeatureFlagDependencyCallStackSize = 0;
    return !this.checkFeatureFlagDependency(this.featureFlags.value, featureFlag);
  }

  /**
   * check one or more feature flags
   */
  private checkFeatureFlag(featureFlags: Record<string, boolean>, name: string | string[], mode?: undefined | '' | 'OR' | 'AND') {

    if (Array.isArray(name)) {
      let ret = false;
      let tmpRet = true;

      if (mode === undefined || mode === '') {
        mode = 'AND';
      }

      name.forEach(e => {
        if (mode === 'AND') {
          if (featureFlags[e] === false || featureFlags[e] === undefined) {
            tmpRet = false;
          }

          ret = !!tmpRet && !!this.checkFeatureFlagDependency(featureFlags, e);
        } else {
          if (featureFlags[e] === true) {
            ret = !!this.checkFeatureFlagDependency(featureFlags, e);
          }
        }
      });

      return ret;

    } else {
      return !!featureFlags[name] && !!this.checkFeatureFlagDependency(featureFlags, name);
    }
  }

  private getFeatureToggles(contract: Contract) {
    if (!contract) {
      return;
    }

    if (contract.feature_flags) {
      this.featureFlags.next(contract.feature_flags);
    }
    this.ready$.next(true);
  }

  get allActiveFeatureFlags$() {
    return this.featureFlags.pipe(
      filter(ff => ff !== undefined),
      map(ff => {
        const ret: string[] = [];
        _.forEach(ff, (flag, feature) => {
          if (flag) {
            ret.push(feature);
          }
        });

        return ret;
      })
    );
  }
}
